import Design from "./Dashboard/Design"
const Designs = () => {
    const designs = [
        require("../designs/frame1.png"),
        require("../designs/frame2.png"),
        require("../designs/frame3.png"),
        require("../designs/frame4.png"),
        require("../designs/frame5.png"),
        require("../designs/frame6.jpg"),
        
    ]
    return (
        <div className="designContainer">
            <div className="designFigma">
                <iframe src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FQPKTSclESsqZI7JE3z9IQf%2FCodesmith%3Fnode-id%3D1%253A2%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D1%253A2" allowFullScreen></iframe>
            </div>
            <div className="designWrap">
                {
                    designs.map((design, index) =>
                        <Design design={design} key={index} />
                    )
                }
            </div>

        </div>
    )
}

export default Designs