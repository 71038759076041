import Portal from './Portal';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Landingpage from './components/Landingpage';
import Project from './components/Project';
import WebsiteProject from './components/WebsiteProject';
import About from './components/About';
import Photos from './components/Photos';
import Designs from './components/Designs';
import NotFound from './components/NotFound';


function App() {
  const webData = {
    title: 'RICMG',
    contact: 'a personal website, for learning, for fun.',
    url: 'ricmg.com'
  }

  return (

    <BrowserRouter>
      <Routes path='/'>
        <Route
          path="*"
          element={<Navigate to="/dashboard/404" replace />}
        />
        
        <Route index element={<Portal />} />
        <Route path='/dashboard' element={<Landingpage webData={webData} />}>
          <Route path='projects' element={<Project title='Projsssects' />} />
          <Route path='websites' element={<WebsiteProject />} />
          <Route path='about' element={<About />} />
          <Route path='designs' element={<Designs />} />
          <Route path='photos' element={<Photos />} />
          <Route path='404' element={<NotFound />} />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}


export default App;
