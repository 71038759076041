import {Link} from 'react-router-dom'
import {FaYoutubeSquare, FaFacebookSquare, FaTwitterSquare, FaGithubSquare} from 'react-icons/fa'

const Footer = ({webData}) => {
    return (
        <>

<div className="container-fluid my-5 dashboardFooter">

<div className="text-center text-lg-start text-white" >
 
<div>
        <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">

                <div className="col-md-7 col-lg-8 text-center text-md-start">

                    <div className="p-3 removeSelect">
                        © 2020 Copyright:
                        <Link className="text-white footerURL" to="/"> {webData.url}</Link>
                    </div>

                </div>



                <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">

                <a className="dashboardLink" role="button"><FaGithubSquare/></a>


                    <a className="dashboardLink" role="button"><FaTwitterSquare/></a>


                    <a className="dashboardLink" role="button"><FaFacebookSquare/></a>


                    <a className="dashboardLink" role="button"><FaYoutubeSquare/></a>
                </div>

            </div>
        </section>

    </div>
</div>

</div >


        </>
    )
}

export default Footer