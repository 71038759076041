
const ProjectTemp = ({task}) => {
  return (
    <div>
         <div className="row projectContainer">
            <h4><a href={task.link} target="_blank" rel="noopener noreferrer">{task.title}</a> </h4>
            <div className="imgContainer">
                <img src={task.img} className="img-fluid" alt="Responsive image" ></img>
            </div>
            {
                task.languages.map((language, index) =>
                    <span className="noselect" key={index}>{language}</span>
                )
            }
            {renderComponent(task.demo)}
            <p>
                
                {task.desc}
            </p>

        </div>
        <div className="divider div-transparent div-arrow-down"></div>
    </div>
  )
}

function renderComponent(demo) {
    if (demo.length > 0) {
        return <span className="noselect demoVid"><a className="demoLink" href={demo} target="_blank">Demo Video</a></span>;
    }
}

export default ProjectTemp