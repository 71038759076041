import ProjectTemp from "./ProjectTemp";

const ProjectTemplate = ({tasks}) => {
    return (
        <>
            {
                tasks.map((task,index) =>
                    <ProjectTemp key={index} task={task}/>
                    
                )
            }
        </>
    )
}



export default ProjectTemplate