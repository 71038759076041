import webImg1 from '../img/About-Photography.jpg';
import webImg2 from '../img/About-Photography2.jpg';
import webImg3 from '../img/Stocks.jpg';
import webImg4 from '../img/diy-car.jpg';
import webImg5 from '../img/car.png';
import webImg6 from '../img/pc.jpg';
const About = () => {
    return (
        <div className="aboutContainer">
            <h1 className="headTitle">Hi there. It's me, Ricky.</h1>
            <p className="text-center">This website is primarily just for fun and secondarily an online portfolio of some sort. <br /> I am a tech enthusiast and I do a lot of digital hobbies and some other things.</p>

            <div className="row aboutWrap">
                <div className="col-sm-6">
                    <h3>Coding</h3>
                    <p>As an Information Technology Graduate, I did enjoy coding for a while and, I still do. The only problem is I do not code consistently. I've been thinking about trying to do it professionally rather than as a hobby.</p>
                </div>
                <div className="col-sm-6">
                    <iframe className="codeDemo" width="560" height="315" src="https://www.youtube.com/embed/MDXa63WSWH0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            <div className="row aboutWrap">
                <h3>Photography and Editing</h3>
                <p>As an Information Technology Graduate, I did enjoy coding for a while and, I still do. The only problem is I do not code consistently. I've been thinking about trying to do it professionally rather than as a hobby.</p>
            </div>
            <div className="row ">
                <div className="col-sm-6">
                    <img className="img-fluid" src={webImg1} />
                </div>
                <div className="col-sm-6">
                    <img className="img-fluid" src={webImg2} />
                </div>
            </div>
            <div className="row aboutWrap">
                <div className="col-sm-6">
                    <h3>Stocks</h3>
                    <p>This is an investment or rather a skill I always wanted to learn. Day trading might be difficult considering myself, but midterm to long term investment might be a smart move. I've been studying how to be consistently profitable, though I have a long way a head of me.</p>
                </div>
                <div className="col-sm-6">
                    <img className="img-fluid" src={webImg3} />
                </div>
            </div>
            <div className="row aboutWrap">
                <h3>Car</h3>
                <p>I don't necessarily like driving but I enjoy tweaking my car, it's old, yes, but that's what makes it okay to upgrade. There might be times you break something, but at least you are learning something. And doing DIY saves you some money, it might not be a lot, but it's rewarding accomplishing something and doing things on your own. Being an indoor person, having your hands dirty once in a while is not that bad.</p>
            </div>
            <div className="row ">
                <div className="col-sm-6">
                    <img className="img-fluid" src={webImg4} />
                </div>
                <div className="col-sm-6">
                    <img className="img-fluid" src={webImg5} />
                </div>
            </div>
            <div className="row aboutWrap">
                <div className="col">
                    <h3>Computers</h3>
                    <p>I do enjoy tweaking with computer hardware and software as well. Since high school, I enjoys exploring computers because of how broad it is. And I still have a lot of things I want and need to learn.</p>
               
                    <img className="img-fluid" src={webImg6} />
                </div>
            </div>
        </div>
    )
}

export default About