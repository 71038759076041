import '../assets/projectscss.scss';
import ProjectTemplate from './Dashboard/ProjectTemplate';

const Project = ({ title }) => {
  const tasks = [
    {
      "link": "https://rmgtech.wordpress.com/2018/04/25/zero-voice-recognition-system-ongoing/",
      "img": require("../img/zerofull.png"),
      "desc": "This application is a personal project like most of the others. So most of the functionalities are more incline to my personal use. Zero, is a voice command system capable of  Multiple Media Commands, Network Connectivity Commands, Download Video/Audio Commands, Message Sending, etc. It’s a long term personal project. You can try using it, the initial debug files are inside the link above.",
      "title": "Zero Voice Command System",
      "languages": ["Languange: C#", "IDE Software: Visual Studio Community"],
      "demo": "https://www.youtube.com/watch?v=MDXa63WSWH0",
    }, {
      "link": "https://rmgtech.wordpress.com/2018/04/20/timekeeping-semi-auto-payroll-uncomplete-4-20-2018/",
      "img": require("../img/mess.png"),
      "desc": "Payroll & Timekeeping System is the first C# project I have completed or rather it’s the first project where I learned how to use C#. The timekeeping is not fool proof and it still need a person monitoring it especially when there is a night shift. The payroll does basic computation of someone salary with the use of number of hours an employee have work for a range of days multiplied by hourly rate of the employee. The produced amount can be edited by the end user.",
      "title": "C# Project #1 – Payroll & Timekeeping",
      "languages": ["Languange: C#", "IDE Software: Visual Studio Community", "MySQL"],
      "demo": "https://www.youtube.com/watch?v=xx-ndbq2YGY",
    },
    {
      "link": "https://rmgtech.wordpress.com/2018/05/02/fast-food-system-trial/",
      "img": require("../img/ffst.png"),
      "desc": "Created just to try out some things.",
      "title": "Fast Food System Trial",
      "languages": ["Languange: C#", "IDE Software: Visual Studio Community", "MySQL"],
      "demo": "",
    },
    {
      "link": "https://rmgtech.wordpress.com/2018/04/09/new-pos-system-project-1-ongoing/",
      "img": require("../img/vb1.png"),
      "desc": "Created to try out a better GUI + Database",
      "title": "PoS Project #1",
      "languages": ["Languange: C#", "IDE Software: Visual Studio Community", "MySQL"],
      "demo": "",
    },
    {
      "link": "#",
      "img": require("../img/vb2.png"),
      "desc": "Created to try out a better GUI",
      "title": "Movie Rental Interface",
      "languages": ["Languange: VB.NET", "IDE Software: Visual Studio Community"],
      "demo": "",
    },
    {
      "link": "https://drive.google.com/file/d/1s2uyMiXGqa_otQdgGVWS0sRpU-FW76xe/view",
      "img": require("../img/vb3.png"),
      "desc": "Software Engineering Project",
      "title": "ABC Salon",
      "languages": ["Languange: VB.NET", "IDE Software: Visual Studio Community", "MySQL"],
      "demo": "",
    },
    {
      "link": "https://drive.google.com/file/d/1I06RgNovKNPrmfgPRmHxhfrOYxzc3XMF/view",
      "img": require("../img/vb4.png"),
      "desc": "Student Project",
      "title": "Student Information",
      "languages": ["Languange: VB.NET", "IDE Software: Visual Studio Community", "MySQL"],
      "demo": "",
    },
    {
      "link": "https://drive.google.com/drive/folders/1cd4GhtrfgYqgq_vRu1Wyx_jVrDJqMtV6",
      "img": require("../img/vb5.png"),
      "desc": "Student Project",
      "title": "Discrete Mathematics Matrix [2×2, 3×3, 4×4], Cramers, Sorting Calculator",
      "languages": ["Languange: VB.NET", "IDE Software: Visual Studio Community"],
      "demo": "",
    },
    {
      "link": "https://drive.google.com/drive/folders/1cd4GhtrfgYqgq_vRu1Wyx_jVrDJqMtV6",
      "img": require("../img/vb6.png"),
      "desc": "Student Project",
      "title": "CHECKLIST",
      "languages": ["Languange: VB.NET", "IDE Software: Visual Studio Community", "MySQL"],
      "demo": "",
    }]

  return (
    <div className="projContainer">
      <ProjectTemplate tasks={tasks} />
      <p className="text-center">I did have some other projects sadly I lost the files for it.</p>
    </div>
  )
}

export default Project