import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Routes,
    Route,
    useLocation,
    Outlet
} from "react-router-dom";
import '../assets/landingpage.scss';
import Navbar from './Dashboard/Navbar';
import Footer from './Dashboard/Footer';
import Project from './Project';


const Landingpage = ({ webData }) => {

    return (
        <>
            <div className="landingPage">
                <div className="dashboardWrap">
                    <div className="header bg py-2">
                        <div className="container text-white">
                            <div className="row pb-5">
                                <div className="col removeSelect">
                                    <div className="dashboard-title">{webData.title}</div>
                                    <div className="dashboard-subtitle">{webData.contact}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Navbar />
                    <div className="container">
                        <Routes>
                          
                            <Route path='/' element={(
                                <>
                                    <Project />
                                </>
                            )} />
                        </Routes>
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer webData={webData} />
        </>
    )
}

export default Landingpage