import React from 'react'
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    Outlet
} from "react-router-dom";
import { FaUndo } from 'react-icons/fa'

const Navbar = () => {
    const location = useLocation()
    return (
        <>
            <span className="position-absolute trigger"></span>
            <nav className="navbar navbar-expand-sm sticky-top navbar-dark bg-nav-mod py-4">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/dashboard/projects' && 'active'} ${location.pathname === '/dashboard' && 'active'}`} aria-current="page" to="projects">Programs</Link>

                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/dashboard/websites' && 'active'}`} to="websites">Websites</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/dashboard/designs' && 'active'}`} to="designs">Designs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/dashboard/photos' && 'active'}`} to="photos">Photos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/dashboard/about' && 'active'}`} to="about">About</Link>
                            </li>
                        </ul>
                        <Link to="/"><FaUndo style={{ color: '#fff', cursor: 'pointer' }} /></Link>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar