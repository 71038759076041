import mainAvatar from'../profilephoto1.jpg';
import avatar from'../rotatingCircle.svg';
import '../assets/homeCss.scss';

const homeAvatar = () => {
  return (
    <div className='homeAvatar'>
        
        <div className='innerContent'>
             <img src={mainAvatar} className="mainAvatar removeSelect" alt="logo" />     
        </div>
        <div className='innerContent rotatingImage'>
            <img src={avatar} className="App-avatar removeSelect" alt="logo" />    
        </div>
    </div>
  )
}

export default homeAvatar