import Photo from "./Dashboard/Photo"

const Photos = () => {
    const images = [
        require("../photos/image1.jpg"),
        require("../photos/image3.jpg"),
        require("../photos/image5.jpg"),
        require("../photos/image6.jpg"),
        require("../photos/image7.jpg"),
        require("../photos/image8.jpg"),
        require("../photos/image9.jpg"),
        require("../photos/image10.jpg"),
        require("../photos/image11.jpg"),
        require("../photos/image12.jpg")
    ]
    return (
        <div className="photosContainer">
            {
                images.map((image) =>
                    <Photo image={image}/>
                )
            }
        </div>
    )
}

export default Photos