import webImg1 from '../img/LBASS-WEBSITE-VERSION-1.jpg';
import webImg2 from '../img/LBASS-WEBSITE-VERSION-2.jpg';

const WebsiteProject = () => {
    return (
        <div>
            <div className="row webProjects">
                <div className="col-6 webProjectWrap">
                    <h4>
                        <a href="https://drive.google.com/file/d/1k28W-V-AXasP478LXioMQulIEffeqxvH/view?usp=sharing" target="_blank" rel="noreferrer" alt="x" >
                            Laguna BelAir Science School Website</a></h4>
                    <span className="noselect">HTML</span><span className="noselect">BootStrap</span>
                    <a href="https://drive.google.com/file/d/1k28W-V-AXasP478LXioMQulIEffeqxvH/view?usp=sharing" target="_blank" rel="noreferrer" alt="x" >
                        <div className="img-wrap"><img className="img-fluid" src={webImg1} /></div>
                    </a>

                    <p>
                        Laguna BelAir Science School Website which I made responsive and maintained while working there.
                    </p>
                </div>
                <div className="col-6 webProjectWrap">
                    <h4>
                        <a href="https://drive.google.com/file/d/1jo04nlNucP4g66ebo8sroH1hkxl3Ul7v/view?usp=sharing" target="_blank" rel="noreferrer" alt="x" >
                            Laguna BelAir Science School Website Proposal</a></h4>
                    <span className="noselect">Wordpress</span> <span className="noselect">BootStrap</span>
                    <a href="https://drive.google.com/file/d/1jo04nlNucP4g66ebo8sroH1hkxl3Ul7v/view?usp=sharing" target="_blank" rel="noreferrer" alt="x" >
                        <div className="img-wrap"><img className="img-fluid" src={webImg2} /></div>
                    </a>

                    <p>
                        Laguna BelAir Science School Website which I made responsive and maintained while working there.
                    </p>
                </div>


                <div className="divider div-transparent div-arrow-down"></div>
                
            </div>
        </div>
    )
}

export default WebsiteProject