
import './App.css';
import Particle from './components/Particle';
import Menu from './components/Menu';
import HomeAvatar from './components/HomeAvatar';



const Portal = () => {
  return (
    <div className="App">
      <header className="header">
      <Particle/>
        <div className='homeMenu'>
          <HomeAvatar/>
          <Menu/>
        </div>
      </header>
    </div>
  )
}

export default Portal