

import '../assets/buttons.scss';
const Menu = () => {
    return (

        <div className='homeMenuButtons'>
            <section className="buttons">
                <h3 className='homeUserName noselect'>Ricky Gonzales</h3>
                <h6 className='homeUserTitle noselect'>Web Developer / System Administrator</h6>

                <div className="containerPortal">

                   

                    <a href="/dashboard" className="btnPortal btnPortal-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Portfolio
                    </a>
                    <a href="https://www.linkedin.com/in/ricky-gonzales-350a58241/" className="btnPortal btnPortal-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        LinkedIn
                    </a>
                    <a href="https://blog.ricmg.com/" className="btnPortal btnPortal-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Blog
                    </a>
                    <a href="#" className="btnPortal btnPortal-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Contact
                    </a>

                    <p className='noselect'>
                        ©2020 RicMG. All rights reserved
                    </p>

                </div>

                <div className="containerPortal">
                </div>
            </section>
        </div>
    )
}

export default Menu