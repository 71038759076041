import img404 from '../404.jpg';
const NotFound = () => {
    return (
        <div className="errorContainer">
            <div className='errorWraps'>
                <div className='errorWrap'>
                    <img src={img404} className="img-fluid" />
                </div>
            </div>
        </div>
    )
}

export default NotFound